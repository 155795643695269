<template lang="">
    <div class="field">
        <div class="grid formgrid mt-6 px-4">
            <div class="field col-12">
                <div class="grid mt-2 mb-2" v-if="formData?.cadastros?.find((cad) => cad.descricao === 'Cliente')">
                    <div class="field col-6">
                        <span :style="{ color: 'red' }">*</span>
                        <label for="quemAgenda">Quem agenda</label>
                        <Dropdown
                            id="quemAgenda"
                            v-model="quemAgenda"
                            :class="{ 'p-invalid': submitted && !quemAgenda }"
                            :options="agendaAvisa"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                        />
                        <small v-if="submitted && !quemAgenda" class="p-error">É obrigatório informar quem agenda. </small>
                    </div>
                    <div class="field col-6">
                        <span :style="{ color: 'red' }">*</span>
                        <label for="quemAvisa">Quem avisa</label>
                        <Dropdown
                            id="quemAvisa"
                            v-model="quemAvisa"
                            :class="{ 'p-invalid': submitted && !quemAvisa }"
                            :options="agendaAvisa"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                        />
                        <small v-if="submitted && !quemAvisa" class="p-error">É obrigatório informar quem avisa</small>
                    </div>
                </div>
            </div>
            <div class="field col-6">
                <label class="font-medium">Novo Contrato</label>
            </div>
            <div class="col-6 flex justify-content-end">
                <input accept=".xlsx" ref="files" type="file" id="file" class="hidden" @change="handleFilesUpload()" />
                <Button class="p-button w-auto" icon="pi pi-upload" @click="addFiles()" label="Importar Planilha" />
            </div>
            <div class="field col-12 mt-5">
                <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
                <div v-if="!loading">
                    <TabView>
                        <TabPanel v-for="(cadastro, index) in cadastros" :key="index" :header="cadastro.sheetName">
                            <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'CARGO'">
                                <Column field="nome" header="Nome">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CARGO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nome')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CARGO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nome') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.nome }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="descricao" header="Descrição eSocial">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CARGO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'descricao')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CARGO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'descricao') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.descricao }}
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'SETOR'">
                                <Column field="nome" header="Nome">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'SETOR')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nome')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'SETOR')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nome') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.nome }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="descricao" header="Descrição eSocial">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'SETOR')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'descricao')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'SETOR')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'descricao') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.descricao }}
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'CLIENTE'">
                                <Column field="cnpjEmpresa" header="CNPJ Empresa">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cnpjEmpresa')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cnpjEmpresa') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.cnpjEmpresa }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="razaoSocial" header="Razão Social">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'razaoSocial')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'razaoSocial') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.razaoSocial }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="nomeFantasia" header="Nome Fantasia">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nomeFantasia')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nomeFantasia') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.nomeFantasia }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="IE" header="Inscrição Estadual">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.IE }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="inscricaoMunicipal" header="Inscrição Municipal">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.inscricaoMunicipal }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="CNAE" header="CNAE">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CNAE')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CNAE') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.CNAE }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="grauRisco" header="Grau de Risco">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'grauRisco')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'grauRisco') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.grauRisco }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="CEP" header="CEP">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CEP')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CEP') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.CEP }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="logradouro" header="Logradouro">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'logradouro')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'logradouro') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.logradouro }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="numero" header="Número">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'numero')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'numero') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.numero }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="cidade" header="Cidade">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cidade')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cidade') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.cidade }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="dataFechamentoContrato" header="Data Fechamento Contrato">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'dataFechamentoContrato')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'CLIENTE')
                                                    ?.items?.find(
                                                        (find) => find.index === slotProps.index && find.field === 'dataFechamentoContrato'
                                                    ) && 'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ $filters.formatDateOnly(slotProps.data?.dataFechamentoContrato) }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="emailContato" header="E-mail Contato">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.emailContato }}
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'UNIDADE'">
                                <Column field="cnpjEmpresa" header="CNPJ Empresa">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cnpjEmpresa')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cnpjEmpresa') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.cnpjEmpresa }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="razaoSocial" header="Razão Social">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'razaoSocial')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'razaoSocial') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.razaoSocial }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="nomeFantasia" header="Nome Fantasia">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nomeFantasia')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nomeFantasia') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.nomeFantasia }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="IE" header="Inscrição Estadual">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.IE }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="inscricaoMunicipal" header="Inscrição Municipal">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.inscricaoMunicipal }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="CNAE" header="CNAE">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CNAE')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CNAE') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.CNAE }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="grauRisco" header="Grau de Risco">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'grauRisco')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'grauRisco') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.grauRisco }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="CEP" header="CEP">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CEP')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'CEP') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.CEP }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="logradouro" header="Logradouro">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'logradouro')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'logradouro') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.logradouro }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="numero" header="Número">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'numero')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'numero') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.numero }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="cidade" header="Cidade">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cidade')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cidade') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.cidade }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="dataFechamentoContrato" header="Data Fechamento Contrato">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'dataFechamentoContrato')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'UNIDADE')
                                                    ?.items?.find(
                                                        (find) => find.index === slotProps.index && find.field === 'dataFechamentoContrato'
                                                    ) && 'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ $filters.formatDateOnly(slotProps.data?.dataFechamentoContrato) }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="emailContato" header="E-mail Contato">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.emailContato }}
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                            <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'FUNCIONÁRIO'">
                                <Column field="cnpjUnidade" header="CNPJ Unidade">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cnpjUnidade')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cnpjUnidade') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.cnpjUnidade }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="nomeFuncionario" header="Nome Funcionário">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nomeFuncionario')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'nomeFuncionario') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.nomeFuncionario }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="cargo" header="Cargo">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cargo')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cargo') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.cargo ? slotProps.data?.cargo : ' ' }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="setor" header="Setor">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'setor')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'setor') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.setor }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="matricula" header="Matrícula">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.matricula }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="dtNascimento" header="Dt. Nascimento">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'dtNascimento')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'dtNascimento') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ $filters.formatDateOnly(slotProps.data?.dtNascimento) }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="sexo" header="Sexo">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'sexo')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'sexo') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.sexo }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="situacao" header="Situação">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'situacao')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'situacao') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.situacao }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="dtAdmissao" header="Dt. Admissão">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'dtAdmissao')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'dtAdmissao') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ $filters.formatDateOnly(slotProps.data?.dtAdmissao) }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="cpf" header="CPF">
                                    <template #body="slotProps">
                                        <div
                                            v-tooltip.bottom="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cpf')
                                                    ?.message?.toString()
                                            "
                                            :style="
                                                errors
                                                    ?.find((error) => error.sheetName === 'FUNCIONÁRIO')
                                                    ?.items?.find((find) => find.index === slotProps.index && find.field === 'cpf') &&
                                                'background: #E75959 !important; padding: 13px; border-radius: 6px;'
                                            "
                                        >
                                            {{ slotProps.data?.cpf }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="ctps" header="CTPS">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.ctps }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="estadoCtps" header="Estado CTPS">
                                    <template #body="slotProps">
                                        <div>
                                            {{ slotProps.data?.estadoCtps }}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="dataUltimoExame" header="Data Último Exame">
                                    <template #body="slotProps">
                                        <div>
                                            {{ $filters.formatDateOnly(slotProps.data?.dataUltimoExame) }}
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button label="Voltar" class="p-button-outlined p-button-primary mr-2 w-15rem" @click="prevPage()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { read, utils } from 'xlsx';
import { getClientBase } from '@/services/http';
import BaseService from '../../../services/BaseService';
import QuemAgendaAvisa from '../../../enums/QuemAgendaAvisa';
import { getCurrentCompany } from '@/services/store';
import { limitarCaracteres } from '../../../services/auth';

export default {
    props: {
        formData: Object
    },
    data() {
        return {
            loading: false,
            submitted: false,
            validationErrors: {},
            files: [],
            cadastros: [],
            quemAgenda: null,
            quemAvisa: null,
            agendaAvisa: [],
            errors: [],
            importSuccess: false
        };
    },
    created() {
        this.$serviceCompanies = new BaseService('/companies');
        this.cadastros = [];
    },
    async mounted() {
        await this.quemAgendaAvisa();
    },
    methods: {
        addFiles() {
            if (this.files.length > 0) {
                document.getElementById('file').value = '';
            }
            this.$refs.files.click();
        },
        async handleFilesUpload() {
            this.submitted = true;
            this.loading = true;
            this.cadastros = [];
            this.errors = [];
            if (this.validateForm()) {
                let uploadedFiles = this.$refs.files.files;
                for (let i = 0; i < uploadedFiles.length; i++) {
                    this.files.push(uploadedFiles[i]);
                }

                const file = uploadedFiles[0];
                const reader = new FileReader();
                reader.onload = async (e) => {
                    let data = e.target.result;
                    data = new Uint8Array(data);
                    const workbook = read(data, {
                        type: 'array',
                        cellDates: true
                    });

                    if (this.formData.cadastros?.length !== workbook.SheetNames?.length) {
                        this.loading = false;
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Atenção!',
                            detail: 'Planilha inválida',
                            life: 3000
                        });
                        return;
                    }

                    for (const cadastro of this.formData.cadastros) {
                        const cadastroSelected = workbook.SheetNames.find((sheetName) => sheetName === cadastro.descricao.toUpperCase());
                        if (!cadastroSelected) {
                            this.loading = false;
                            this.$toast.add({
                                severity: 'warn',
                                summary: 'Atenção!',
                                detail: `Aba de ${cadastro.descricao} não encontrado(a) na planilha`,
                                life: 3000
                            });
                            return;
                        }
                    }

                    for (let i = 0; i < workbook.SheetNames.length; i++) {
                        const sheetName = workbook.SheetNames[i];
                        const worksheet = workbook.Sheets[sheetName];
                        let items;

                        if (sheetName === 'SETOR' || sheetName === 'CARGO') {
                            items = utils.sheet_to_json(worksheet, {
                                skipHeader: false,
                                header: ['nome', 'descricao']
                            });
                        } else if (sheetName === 'UNIDADE' || sheetName === 'CLIENTE') {
                            items = utils.sheet_to_json(worksheet, {
                                skipHeader: false,
                                header: [
                                    'cnpjEmpresa',
                                    'razaoSocial',
                                    'nomeFantasia',
                                    'IE',
                                    'inscricaoMunicipal',
                                    'CNAE',
                                    'grauRisco',
                                    'CEP',
                                    'logradouro',
                                    'numero',
                                    'cidade',
                                    'dataFechamentoContrato',
                                    'emailContato'
                                ]
                            });
                        } else if (sheetName === 'FUNCIONÁRIO') {
                            items = utils.sheet_to_json(worksheet, {
                                skipHeader: false,
                                header: [
                                    'cnpjUnidade',
                                    'nomeFuncionario',
                                    'cargo',
                                    'setor',
                                    'matricula',
                                    'dtNascimento',
                                    'sexo',
                                    'situacao',
                                    'dtAdmissao',
                                    'cpf',
                                    'ctps',
                                    'estadoCtps',
                                    'dataUltimoExame'
                                ]
                            });
                        }
                        items.splice(0, 1);
                        this.cadastros.push({ sheetName, items });
                    }

                    if (this.formData.tipoImportacao === 'NC') {
                        if (this.cadastros.find((cad) => cad.sheetName === 'CLIENTE')?.items.length >= 2) {
                            this.loading = false;
                            this.cadastros = [];
                            this.$toast.add({
                                severity: 'warn',
                                summary: 'Atenção!',
                                detail: 'É permitido inserir apenas 1 cliente para o tipo de importação "NOVO CONTRATO"',
                                life: 3000
                            });
                            return;
                        }
                    }

                    const response = await getClientBase().post('/importacao-estrutura/importa-dados', {
                        quemAgenda: this.quemAgenda,
                        quemAvisa: this.quemAvisa,
                        clienteId: this.formData.clienteId,
                        cadastros: this.cadastros,
                        tipoImportacao: this.formData.tipoImportacao
                    });
                    this.importSuccess = response.data.success;
                    if (!response.data.success) {
                        this.errors.push(...response.data.items);
                    } else {
                        const dataImportacaoEstrutura = {
                            customerId: this.formData.tipoImportacao === 'NC' ? response.data.customerId : this.formData.clienteId,
                            tipoImportacao: this.formData.tipoImportacao,
                            etapaProcesso: 'C',
                            cadastros: this.formData.cadastros.map((cadastro) => {
                                return cadastro.id;
                            })
                        };

                        if (Number(this.$route.params.id) > 0) {
                            await getClientBase().patch(`/importacao-estrutura/${this.$route.params.id}`, {
                                ...dataImportacaoEstrutura,
                                id: Number(this.$route.params.id)
                            });
                        } else {
                            await getClientBase().post('/importacao-estrutura', dataImportacaoEstrutura);
                        }
                        this.nextPage();
                    }
                    this.loading = false;
                };
                reader.readAsArrayBuffer(file);
            }
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', {
                    formData: {
                        cadastrosConclusao: this.cadastros,
                        tipoImportacao: this.tipoImportacao,
                        step: 5,
                        link: ['/importacao-cadastro-estrutura/importacao-validacao']
                    },
                    pageIndex: 3
                });
            }
        },
        prevPage() {
            this.$emit('prev-page', { formData: { pageIndex: 3, step: 3 } });
        },
        validateForm() {
            if (this.formData.cadastros.find((cad) => cad.descricao === 'Cliente')) {
                if (!this.quemAvisa) this.validationErrors['quemAvisa'] = true;
                else delete this.validationErrors['quemAvisa'];

                if (!this.quemAgenda) this.validationErrors['quemAgenda'] = true;
                else delete this.validationErrors['quemAgenda'];
            }

            return !Object.keys(this.validationErrors).length;
        },
        async quemAgendaAvisa() {
            const companyId = getCurrentCompany();
            const { data } = await this.$serviceCompanies.findById(companyId);
            const nomeReduzidoEmpresa = limitarCaracteres(data.name, 38);
            this.agendaAvisa = [
                { label: 'Solicitante', value: QuemAgendaAvisa.SOLICITANTE },
                { label: nomeReduzidoEmpresa, value: QuemAgendaAvisa.EMPRESA },
                { label: 'Usuário Escolhe', value: QuemAgendaAvisa.USUARIO_ESCOLHE }
            ];
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep(.tipo-importacao) {
    border-radius: 6px;
}

#quemAgenda {
    border-radius: 6px;
}

#quemAvisa {
    border-radius: 6px;
}
</style>
